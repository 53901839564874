import React from "react";
import  secureLocalStorage  from  "react-secure-storage"; import { decryptToken} from "./../../../AppUtility"; import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/mbusi.png";
import avatar from "../../../images/avatar/1.jpg";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification}) => {
   var path = window.location.pathname.split("/");
   var name = path[path.length - 1].split("-");
   var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
   var finalName = filterName.includes("app")
      ? filterName.filter((f) => f !== "app")
      // : filterName.includes("ui")
      // ? filterName.filter((f) => f !== "ui")
      // : filterName.includes("uc")
      // ? filterName.filter((f) => f !== "uc")
      // : filterName.includes("basic")
      // ? filterName.filter((f) => f !== "basic")
      // : filterName.includes("form")
      // ? filterName.filter((f) => f !== "form")
      // : filterName.includes("table")
      // ? filterName.filter((f) => f !== "table")
      // : filterName.includes("page")
      // ? filterName.filter((f) => f !== "page")
      // : filterName.includes("email")
      // ? filterName.filter((f) => f !== "email")
      // : filterName.includes("ecom")
      // ? filterName.filter((f) => f !== "ecom")
      // : filterName.includes("chart")
      // ? filterName.filter((f) => f !== "chart")
      // : filterName.includes("editor")
      // ? filterName.filter((f) => f !== "editor")
      : filterName;
	
	var page_name = (finalName.join(" ") === ' ')?'Dashboard':finalName.join(" ");	
	  
   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                     <div
                        className="dashboard_bar"
                        style={{ textTransform: "capitalize" }}
                     >
                        {page_name}
                     </div>
                  </div>

                  <ul className="navbar-nav header-right">
                     <li className="nav-item">
						 {/* <div className="input-group search-area d-lg-inline-flex d-none">
                           <input
                              type="text"
                              className="form-control"
                              placeholder="Search something here..."
                           />
                           <div className="input-group-append">
                              <span className="input-group-text">
                                 <i className="flaticon-381-search-2"></i>
                              </span>
                           </div>
                        </div> */}
                     </li>
					  
                     {/* <li className="nav-item dropdown notification_dropdown">
                       
                        <a 
                           
                          href="https://uat-uap.platcorpgroup.com" without rel="noopener noreferrer" target="_blank"
                          className="nav-link bell bell-link"
                          
                        >
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M13.2535 11.998C13.2675 11.2575 13.277 10.013 13.68 9.76C14.0785 9.507 14.8655 9.7415 15.2685 9.7085C15.667 9.6755 15.681 9.385 15.695 9.09M19.355 12.159C19.355 12.159 19.334 9.961 18.952 9.6755C18.563 9.3855 17.785 9.6895 17.387 9.6755C16.9835 9.6615 16.9605 9.3335 16.937 9.0005M13.7455 6.72L12.785 8.7525M18.288 6.8455L19.2895 8.64M16.0325 3.265C17.3305 3.265 18.38 4.521 18.38 6.0675C18.38 7.614 17.33 8.865 16.0325 8.865C14.7295 8.865 13.68 7.614 13.68 6.0675C13.68 4.521 14.73 3.265 16.0325 3.265ZM7.2975 12.67C8.5955 12.67 9.65 13.9775 9.65 15.585C9.65 17.1925 8.5955 18.495 7.2975 18.495C5.9995 18.495 4.945 17.192 4.945 15.585C4.945 13.978 5.9995 12.67 7.2975 12.67Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M5.0435 14.924C5.8635 15.0365 6.6835 15.154 7.185 14.91C7.6865 14.671 7.874 14.076 8.08 14.015C8.286 13.954 8.511 14.432 8.75 14.685C8.994 14.943 9.247 14.9755 9.505 15.013M13.7455 5.505C14.3175 5.5895 14.8935 5.6785 15.2455 5.505C15.6015 5.3365 15.7325 4.905 15.92 4.835C16.1025 4.765 16.337 5.05 16.735 5.224C17.133 5.398 17.6915 5.4535 18.249 5.514M8.38 18.228C8.4925 18.5515 8.61 18.8745 8.975 18.94C9.345 19.0105 9.959 18.8275 10.32 19.39C10.681 19.9525 10.7795 21.2645 10.8775 22.5765M6.735 18.495C6.6415 18.795 6.543 19.09 6.29 19.165C6.032 19.24 5.62 19.09 5.395 19.165C5.165 19.245 5.123 19.5495 5.081 19.8585M13.7035 15.552H18.6335V18.2795H13.7035V15.552Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M8.305 7.4545C8.88738 7.4545 9.3595 6.98238 9.3595 6.4C9.3595 5.81762 8.88738 5.3455 8.305 5.3455C7.72262 5.3455 7.2505 5.81762 7.2505 6.4C7.2505 6.98238 7.72262 7.4545 8.305 7.4545Z" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M8.08 5.28L7.185 4.385L4.275 7.295V10.655H9.2M11.665 1.25L12.11 22.75M1.253 11.768L22.747 12.232M16.168 15.5825L16.126 13.6915H17.026" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                           <path d="M22.75 12C22.75 17.937 17.937 22.75 12 22.75C6.063 22.75 1.25 17.937 1.25 12M22.75 12C22.75 6.063 17.937 1.25 12 1.25C6.063 1.25 1.25 6.063 1.25 12" stroke="black" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                        
                        </a>
                     </li> */}
                     {/* <li className="nav-item dropdown notification_dropdown">
                        <Link to={"#"}
                           className="nav-link bell bell-link"
                           onClick={() => onNote()}
                        >
                          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.4604 0.848846H3.31682C2.64742 0.849582 2.00565 1.11583 1.53231 1.58916C1.05897 2.0625 0.792727 2.70427 0.791992 3.37367V15.1562C0.792727 15.8256 1.05897 16.4674 1.53231 16.9407C2.00565 17.414 2.64742 17.6803 3.31682 17.681C3.53999 17.6812 3.75398 17.7699 3.91178 17.9277C4.06958 18.0855 4.15829 18.2995 4.15843 18.5226V20.3168C4.15843 20.6214 4.24112 20.9204 4.39768 21.1817C4.55423 21.4431 4.77879 21.6571 5.04741 21.8008C5.31602 21.9446 5.61861 22.0127 5.92292 21.998C6.22723 21.9833 6.52183 21.8863 6.77533 21.7173L12.6173 17.8224C12.7554 17.7299 12.9179 17.6807 13.0841 17.681H17.187C17.7383 17.68 18.2742 17.4993 18.7136 17.1664C19.1531 16.8334 19.472 16.3664 19.6222 15.8359L22.8965 4.05007C22.9998 3.67478 23.0152 3.28071 22.9413 2.89853C22.8674 2.51634 22.7064 2.15636 22.4707 1.8466C22.2349 1.53684 21.9309 1.28565 21.5822 1.1126C21.2336 0.93954 20.8497 0.849282 20.4604 0.848846ZM21.2732 3.60301L18.0005 15.3847C17.9499 15.5614 17.8432 15.7168 17.6964 15.8274C17.5496 15.938 17.3708 15.9979 17.187 15.9978H13.0841C12.5855 15.9972 12.098 16.1448 11.6836 16.4219L5.84165 20.3168V18.5226C5.84091 17.8532 5.57467 17.2115 5.10133 16.7381C4.62799 16.2648 3.98622 15.9985 3.31682 15.9978C3.09365 15.9977 2.87966 15.909 2.72186 15.7512C2.56406 15.5934 2.47534 15.3794 2.47521 15.1562V3.37367C2.47534 3.15051 2.56406 2.93652 2.72186 2.77871C2.87966 2.62091 3.09365 2.5322 3.31682 2.53206H20.4604C20.5905 2.53239 20.7187 2.56274 20.8352 2.62073C20.9516 2.67872 21.0531 2.7628 21.1318 2.86643C21.2104 2.97005 21.2641 3.09042 21.2886 3.21818C21.3132 3.34594 21.3079 3.47763 21.2732 3.60301Z" fill="#3E4954"/>
								<path d="M5.84161 8.42333H10.0497C10.2729 8.42333 10.4869 8.33466 10.6448 8.17683C10.8026 8.019 10.8913 7.80493 10.8913 7.58172C10.8913 7.35851 10.8026 7.14445 10.6448 6.98661C10.4869 6.82878 10.2729 6.74011 10.0497 6.74011H5.84161C5.6184 6.74011 5.40433 6.82878 5.2465 6.98661C5.08867 7.14445 5 7.35851 5 7.58172C5 7.80493 5.08867 8.019 5.2465 8.17683C5.40433 8.33466 5.6184 8.42333 5.84161 8.42333Z" fill="#3E4954"/>
								<path d="M13.4161 10.1065H5.84161C5.6184 10.1065 5.40433 10.1952 5.2465 10.353C5.08867 10.5109 5 10.7249 5 10.9481C5 11.1714 5.08867 11.3854 5.2465 11.5433C5.40433 11.7011 5.6184 11.7898 5.84161 11.7898H13.4161C13.6393 11.7898 13.8534 11.7011 14.0112 11.5433C14.169 11.3854 14.2577 11.1714 14.2577 10.9481C14.2577 10.7249 14.169 10.5109 14.0112 10.353C13.8534 10.1952 13.6393 10.1065 13.4161 10.1065Z" fill="#3E4954"/>
							</svg>
                           <span className="badge light text-white bg-primary">5 </span>
                        </Link>
                     </li> */}
                  
                     <li
                        className={`nav-item dropdown header-profile ${
                           toggle === "profile" ? "show" : ""
                        }`}
                        onClick={() => onProfile()}
                     >
                        <a
                           className="nav-link"
                           role="button"
                           data-toggle="dropdown"
                        >
                   
                        {/* <div className="nav-link"> */}
                        <div className="header-info">
								<small>Hi</small>
                        <span>{jwt_decode(decryptToken(secureLocalStorage.getItem("userDetails"))).Name}</span>
                           </div>
                           <img src={profile} width="20" alt="" />
                        {/* </div> */}
                        
                          
                        </a>
                     
                        <div
                           className={`dropdown-menu dropdown-menu-right ${
                              toggle === "profile" ? "show" : ""
                           }`}
                        >
                           {/* <Link
                              to="/app-profile"
                              className="dropdown-item ai-icon"
                           >
                              <svg
                                 id="icon-user1"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-primary"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                 <circle cx="12" cy="7" r="4"></circle>
                              </svg>
                              <span className="ml-2">Profile </span>
                           </Link> */}
                           {/* <Link
                              to="/"
                              className=" ai-icon"
                           >
                              <svg
                                 id="icon-inbox"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="text-success"
                                 width="18"
                                 height="18"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor"
                                 strokeWidth="2"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              >
                                 <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                 <polyline points="22,6 12,13 2,6"></polyline>
                              </svg>
                              <span className="ml-2">Inbox </span>
                           </Link> */}
                           
                          
                           <LogoutPage />
                        </div>
                     </li>
					 
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
